import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,3];

export const dictionary = {
		"/(public)": [14,[4]],
		"/(auth)/(subscription)/dashboard/(global)/account": [~10,[2,3]],
		"/(auth)/(subscription)/dashboard/(global)/billing": [~11,[2,3]],
		"/(auth)/(subscription)/dashboard/(branch)/history": [~6,[2,3]],
		"/(auth)/(subscription)/dashboard/(global)/products": [~12,[2,3]],
		"/(auth)/(subscription)/dashboard/(branch)/settings": [7,[2,3]],
		"/(auth)/(subscription)/dashboard/(branch)/stats": [~8,[2,3]],
		"/(auth)/(subscription)/dashboard/(branch)/survey": [~9,[2,3]],
		"/(public)/demo": [15],
		"/(public)/guides/[slug]": [~16],
		"/(public)/sign-in": [17,[4]],
		"/(public)/sign-in/confirm": [18,[4]],
		"/(public)/sign-out": [19,[4]],
		"/(public)/sign-up": [20,[4]],
		"/(public)/survey": [~21],
		"/(public)/survey/[bill_id]": [~22],
		"/(public)/test": [23,[4]],
		"/(auth)/welcome": [~13]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';